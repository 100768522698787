import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import SignUp from '../components/Account/Register'

export default () => {
  return (
    <Layout>
      <SEO title="Register" />
      <SignUp />
    </Layout>
  )
}
