import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useStyles } from './style'
import { AccountAction } from '../state'
import { navigate } from 'gatsby'
import { ROUTES } from '../../../constant'
import { useTranslation } from 'react-i18next'
import { getLoginInfo } from '../../../utils/localStorage'
import { LayoutState } from '../../Layout/state'
import { SNACKBAR_MESSAGE_LIST } from '../../../globals/variable'

export default function SignUp() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const snackState = useState(LayoutState.snack)

  // local state
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleClickRegister = (event) => {
    AccountAction.register(email, email, password)().then(() => {
      SNACKBAR_MESSAGE_LIST.push({ text: `${t('Registered')} ${email}`, variant: 'success' })
    })
    navigate(ROUTES.HOME)
    setTimeout(function () {
      // navigate(ROUTES.HOME)
      window.location.reload()
    }, 1000)
  }

  // const responseFacebook = (response) => {
  //   console.log('Facebook: ', response)
  //   AccountAction.register(response.name, response.email, response.userID, response.picture.data.url)()
  // }
  //
  // const responseGoogle = (response) => {
  //   const data = response.profileObj
  //   console.log('Google: ', JSON.stringify(response.profileObj))
  //   AccountAction.register(data.name, data.email, data.googleId, data.imageUrl)()
  // }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Register')}
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="email"
                name="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                autoFocus
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value)
                }}
              />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <TextField*/}
            {/*    variant="outlined"*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    name="password"*/}
            {/*    label="Confirm password"*/}
            {/*    type="password"*/}
            {/*    id="password"*/}
            {/*    autoComplete="current-password"*/}
            {/*    onChange={(event) => {*/}
            {/*      setPassword(event.target.value)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Grid>*/}

            {/*<Grid item xs={12}>*/}
            {/*  <FormControlLabel*/}
            {/*    control={<Checkbox value="allowExtraEmails" color="primary" />}*/}
            {/*    label="I want to receive inspiration, marketing promotions and updates via email."*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClickRegister}
          >
            {t('Register')}
          </Button>
        </form>
      </div>

      {/*<FacebookLogin*/}
      {/*  appId={process.env.GATSBY_FACEBOOK_APP_ID}*/}
      {/*  callback={responseFacebook}*/}
      {/*  fields="name,email,picture"*/}
      {/*  textButton="Facebook"*/}
      {/*  render={(renderProps) => (*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      fullWidth*/}
      {/*      size="large"*/}
      {/*      className={classes.fbButton}*/}
      {/*      onClick={renderProps.onClick}*/}
      {/*      // startIcon={<FontAwesomeIcon icon={faFacebookF} size={'1x'} color={CONF.css.blue} />}*/}
      {/*    >*/}
      {/*      <Grid container spacing={1} alignItems="flex-end">*/}
      {/*        <Grid item xs={1}>*/}
      {/*          <FontAwesomeIcon icon={faFacebookF} size={'1x'} color={CONF.css.blue} />*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={11}>*/}
      {/*          <Typography className={classes.fbTypo} variant="body2" gutterBottom>*/}
      {/*            {t('Register with')} Facebook*/}
      {/*          </Typography>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*/>*/}
      {/*<GoogleLogin*/}
      {/*  clientId={process.env.GATSBY_GOOGLE_APP_ID as string}*/}
      {/*  buttonText="Login"*/}
      {/*  onSuccess={responseGoogle}*/}
      {/*  onFailure={responseGoogle}*/}
      {/*  cookiePolicy={'single_host_origin'}*/}
      {/*  // isSignedIn={true}*/}
      {/*  render={(renderProps) => (*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      fullWidth*/}
      {/*      size="large"*/}
      {/*      className={classes.ggButton}*/}
      {/*      onClick={renderProps.onClick}*/}
      {/*      // startIcon={<FontAwesomeIcon icon={faGoogle} size={'1x'} color={CONF.css.red} />}*/}
      {/*    >*/}
      {/*      <Grid container spacing={1} alignItems="flex-end">*/}
      {/*        <Grid item xs={1}>*/}
      {/*          <FontAwesomeIcon icon={faGoogle} size={'1x'} color={CONF.css.red} />*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={11}>*/}
      {/*          <Typography className={classes.ggTypo} variant="body2" gutterBottom>*/}
      {/*            {t('Register with')} Google*/}
      {/*          </Typography>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*/>*/}

      <Button className={classes.signUpNow}  fullWidth onClick={() => navigate(ROUTES.LOGIN)}>
        {t('Already have an account? Sign in')}
      </Button>
    </Container>
  )
}
